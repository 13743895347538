/* eslint-disable import/no-unresolved */
import React from 'react';
import headermagic from '../../assets/headermagic.png';
import './header.css';

const Header = () => (
  <div className="mmk__header section__padding .header__mobile" id="home" style={{
    backgroundImage: "url(/forest-bg.png)",
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',
  }}>
    <div className="mmk__header-content">
      <h1 className="magic__font">THOSE WHO DON'T BELIEVE IN MAGIC MAY NEVER FIND IT</h1>
      <p>Mini Magics are 10,000 magically generated & unique creatures nestled within the Cardano blockchain. Facing the brink of extinction at the hands of utter destruction, our six species came together to form what we now know as The Kingdom.</p>
    </div>
    <div className="mmk__header-image">
      <img src={headermagic} alt="magic"/>
    </div>
  </div>
);

export default Header;
