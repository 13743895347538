import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../logo.svg';
import discord from '../../assets/discord.png';
import twitter from '../../assets/twitter.png';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="mmk__navbar">
    <div className="mmk__navbar-links">
      <div className="mmk__navbar-links_logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="mmk__navbar-links_container">
        <p><a href="#wmmk">SPECIES</a></p>
        <p><a href="https://app.vyfi.io/vaults/minimagics">STAKING</a></p>
        <p><a href="https://mini-magics-kingdom.gitbook.io/untitled/mini-magic-origins">LORE</a></p>
        <p><a href="#team">TEAM</a></p>
      </div>
    </div>
    <div className="mmk__navbar-sign">
      <a href="https://twitter.com/MiniMagicsNFT"><img src={twitter} alt="twitter"/></a>
      <a href="https://discord.gg/PU7mksfxfE"><img src={discord} alt="discord" /></a>
      {/* <a href="https://magical-mint.pxlz.org/"> 
        <button type="button">MINT NOW</button>
      </a> */}
    </div>
    <div className="mmk__navbar-menu">
      {toggleMenu
        ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
        : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
      {toggleMenu && (
      <div className="mmk__navbar-menu_container scale-up-center">
        <div className="mmk__navbar-menu_container-links">
          <p><a href="#wmmk">SPECIES</a></p>
          <p><a href="https://app.vyfi.io/vaults/minimagics">STAKING</a></p>
          <p><a href="https://mini-magics-kingdom.gitbook.io/untitled/mini-magic-origins">LORE</a></p>
          <p><a href="#team">TEAM</a></p>
        </div>
        <div className="mmk__navbar-menu_container-links-sign">
          {/* <a href="https://magical-mint.pxlz.org/"> 
           <button type="button">MINT NOW</button>
          </a> */}
        </div>
      </div>
      )}
    </div>
  </div>
  );
};

export default Navbar;
