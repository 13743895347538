/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { wizard, goblin, witch, dragon, fairy, elf } from './baseimports';
import './whatmmk.css';
import wave from '../../assets/wave.svg'

function Whatmmk() {
  const [baseTitle, setBaseTitle] = useState('WIZARD');
  const [baseDescription, setBaseDescription] = useState('A complex species, Wizards are a neutral good, however their species have maintained a general distaste for Dragons stemming back to quarrels preexisting the kingdom itself. Wizards are crafty, thoughtful, and constantly harnessing their innate intellect to expand a powerful arsenal of magical abilities to unleash on any potential foes.');

  const setValues = (title, desc) => {
    setBaseTitle(title);
    setBaseDescription(desc);
  };

  return (
    <>
      
        <div
        className="mmk__whatmmk"
        id="wmmk"
        style={{
          backgroundImage: `url(${wave})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'repeat-x'
        }}
      >
        <div className="mmk__whatmmk-heading">
          <h1>CLICK A MAGIC TO LEARN ABOUT ITS SPECIES!</h1>
        </div>
        <div className="mmk__whatmmk-container">
          <button onClick={() => setValues('WIZARD', 'A complex species, Wizards are a neutral good, however their species have maintained a general distaste for Dragons stemming back to quarrels preexisting the kingdom itself. Wizards are crafty, thoughtful, and constantly harnessing their innate intellect to expand a powerful arsenal of magical abilities to unleash on any potential foes.')}><img src={wizard} alt="wizard" /></button>
          <button onClick={() => setValues('GOBLIN', 'Masters of trickery and a neutral evil, the Goblins are deceptive, manipulative, and stealthy. They take great pride in their devious acts, but unbeknownst to many, they are in fact voraciously loyal towards their allies. Tread carefully when mingling with this species, there is always more than meets the eye.')}><img src={goblin} alt="goblin" /></button>
          <button onClick={() => setValues('DRAGON', 'Born into difficult circumstances, Dragons were commonly sought out and hunted by the Wizards for their valuable scales, eyes, and bones. However, the Dragons were viewed as allies at last after using their unmatched strength to aid in the creation of the Kingdom. Now with the dissipation of their long lasting conflict, Dragons live a peaceful life indulging in the fumes of their favorite homegrown luxury: Fiddle Weed. Do not let the affable nature of these beasts fool you, the Dragons truly fear no power.')}><img src={dragon} alt="dragon" /></button>
          <button onClick={() => setValues('WITCH', 'A lawful evil force, and rulers of the dark arts, Witches are able to turn their enemies’ dreams into nightmares at the snap of a finger. Witches spend most of their time studying ancient texts, practicing a variety of spells and hexes, but in times of conflict they are known to band together unleashing power so deadly that few would dare to stand in their way.')}><img src={witch} alt="witch" /></button>
          <button onClick={() => setValues('FAIRY', 'A chaotic neutral force, Fairies are quick, agile, and cunning - using their mind-bending pixie dust to achieve unbelievable feats. They are known to possess their victims, corrupting the minds of opponents and stealing their magical abilities. Utilizing Symbiotic schools of magic, the Fairy is a jack and master of all trades.')}><img src={fairy} alt="fairy" /></button>
          <button onClick={() => setValues('ELF', 'A curious and elusive population, the Elves are a lawful good force - able to use the acquisition of knowledge to become exponentially powerful. Guided by moonlight and fueled by consciousness, Elfs find comfort philosophizing under the protection of nightshade. No species are as wise as the Elfs, and most look to the Elfs to become enlightened during times of disarray.')}><img src={elf} alt="elf" /></button>
        </div>
        <div className="base__title"> 
        <h2>{baseTitle}</h2>
        </div>
        <div className="mmk__whatmmk-heading">
          <h2>{baseDescription}</h2>
        </div>
      </div>
    </>
  );
}

export default Whatmmk;
