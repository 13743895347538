import React from 'react';
import { cordona, elitetools, pxlz, onchainsolutions, s3 } from './imports';
import './brand.css';

const Brand = () => (
  <div className="mmk__brand less__padding">
    <div>
    <a href="https://www.vyfi.io/"><img src={cordona} alt="cordona"/></a>
    </div>
    <div>
      <a href="https://www.stratous.io/blockchain"><img src={s3} alt="s3"/></a>
    </div>
    <div>
      <img src={elitetools} alt="elitetools"/>
    </div>
    <div>
      <img src={onchainsolutions} alt="onchainsolutions"/>
    </div>
    <div>
      <a href="https://pxlz.org/"><img src={pxlz} alt="pxlz"/></a>
    </div>
  </div>
);

export default Brand;
