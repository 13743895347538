import React from 'react';
import roadmapImage from '../../assets/roadmap.png';
import './roadmap.css';

const Roadmap = () => (
  <div className="mmk__roadmap" id="roadmap">
    <div className="mmk__roadmap-image">
      <img src={roadmapImage} alt="roadmap" />
    </div>
    {/* <div className="mmk__roadmap-content">
      <h4>A Peak into our Pre-release Roadmap</h4>
      <h1 className="magic__font">The kingdom awaits your arrival. <br /> Mysteries lie beyond.</h1>
      <p>Yet bed any for traveling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment. Party we years to order allow asked of.</p>
    </div> */}
  </div>
);

export default Roadmap;
