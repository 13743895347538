import React from 'react';
import './team.css';
import { bagel, kenz, jnaz, af } from './teamimports';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.1rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

function Team() {
  
  const membersDict = {
    headermagic: {
      panelName: 'panel1', image: { src: bagel },  alt: 'bagel', blurb: 'With an educational background and bachelors degree in Business, Bagel’s passions lie in gaming, and NFT integrated game development. Bagel has worked alongside top Cardano NFT projects to ensure their overall success guided by a strong knowledge base of game theory.', name: 'Bagel'
    },
    kenz: {
      panelName: 'panel3', image: { src: kenz },  alt: 'kenz', blurb: 'Kenz studied Graphic Design, Product Design, and Fine Art at the University of Southern California. When she’s not drawing Magics, she’s oil painting, branding new projects or designing new products.', name: 'Kenz'
    },
    jnaz: {
      panelName: 'panel4', image: { src: jnaz },  alt: 'jnaz', blurb: 'As the Kingdom’s very own in-house web developer, Jnaz also has experience in business operations, technical writing, and graphic design.', name: 'Jnaz'
    },
    af: {
      panelName: 'panel5', image: { src: af },  alt: 'af', blurb: 'With a background in game development, AF is the founder of DEADPXLZ - one of the most successful and well established CNFT collections, as well as the first ever interactive CNFT. ', name: 'AF'
    }
  }
  
  const memberNames = Object.keys(membersDict);
  const [currentPanel, setCurrentPanel] = useState(membersDict[memberNames[0]]);

  const handleClick = panel => (event) => {
    setCurrentPanel(panel)
  }

  return (
    <div className="mmk__fteam section__padding" id="team">
      <div className="mmk__fteam-heading">
        <img src={currentPanel.image.src} alt={currentPanel.alt} />
      </div>
      <div className="mmk__fteam-container" backgroundColor="transparent">
        <div className="mmk__fteam-heading">
          <h1 className="magic__font">OUR TEAM</h1>
          <p>ARTISTS. INNOVATORS. BUILDERS. LEADERS.
          </p>
        </div>
        <div style={{ backgroundColor: '#1B437E', padding: '25px', width: '100%', borderRadius: '8px', font: 'Rubik'}}>
          {memberNames.map(member => {
            let memberData = membersDict[member];
            return (
              <Accordion expanded={currentPanel.panelName === memberData.panelName} onChange={handleClick(memberData)}
              sx={{
                backgroundColor: "transparent",
                width: '100%'
              }}>
              <AccordionSummary aria-controls={`${memberData.panelName}d-content`} id={`${memberData.panelName}d-header`}>
                  <Typography>{memberData.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                    {memberData.blurb}
                </Typography>
              </AccordionDetails>
            </Accordion>
            )
          })}
        </div>
      </div>
    </div>
  );


    // return (
    //   <div className="mmk__fteam section__padding" id="team">
    //     <div className="mmk__fteam-heading">
    //       <img src={jnaz} alt={jnaz.alt} />
    //     </div>
    //     <div className="mmk__fteam-container" backgroundColor="transparent">
    //       <div className="mmk__fteam-heading">
    //         <h1 className="magic__font">OUR TEAM</h1>
    //         <p>ARTISTS. INNOVATORS. BUILDERS. LEADERS.</p>
    //       </div>
    //       <Accordion expanded={expanded === 'panel1'} onChange={handlePanel('panel1')}
    //         sx={{
    //           backgroundColor: "transparent"
    //         }}>
    //         <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
    //           <Typography>Bagel</Typography>
    //         </AccordionSummary>
    //         <AccordionDetails>
    //           <Typography>
    //             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
    //             malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
    //             sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
    //             sit amet blandit leo lobortis eget.
    //           </Typography>
    //         </AccordionDetails>
    //       </Accordion>
    //       <Accordion expanded={expanded === 'panel2'} onChange={handlePanel('panel2')}
    //         sx={{
    //           backgroundColor: "transparent"
    //         }}>
    //         <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
    //           <Typography>Speen</Typography>
    //         </AccordionSummary>
    //         <AccordionDetails>
    //           <Typography>
    //             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
    //             malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
    //             sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
    //             sit amet blandit leo lobortis eget.
    //           </Typography>
    //         </AccordionDetails>
    //       </Accordion>
    //       <Accordion expanded={expanded === 'panel3'} onChange={handlePanel('panel3')}
    //         sx={{
    //           backgroundColor: "transparent"
    //         }}>
    //         <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
    //           <Typography>Kenz</Typography>
    //         </AccordionSummary>
    //         <AccordionDetails>
    //           <Typography>
    //             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
    //             malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
    //             sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
    //             sit amet blandit leo lobortis eget.
    //           </Typography>
    //         </AccordionDetails>
    //       </Accordion>
    //       <Accordion expanded={expanded === 'panel4'} onChange={handlePanel('panel4')}
    //         sx={{
    //           backgroundColor: "transparent"
    //         }}>
    //         <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
    //           <Typography>Jnaz</Typography>
    //         </AccordionSummary>
    //         <AccordionDetails>
    //           <Typography>
    //             DEVELOPER AND ADVISOR. A WIDE-EYED CALI BOY WHO GOT ACCEPTED INTO THE COOL KIDS CLUB.
    //           </Typography>
    //         </AccordionDetails>
    //       </Accordion>
    //       <Accordion expanded={expanded === 'panel5'} onChange={handlePanel('panel5')}
    //         sx={{
    //           backgroundColor: "transparent"
    //         }}>
    //         <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
    //           <Typography>AF</Typography>
    //         </AccordionSummary>
    //         <AccordionDetails>
    //           <Typography>
    //             ADVISOR, DEVELOPER, AND CHAD. FOUNDER OF DEADPXLZ,
    //             A PROJECT ON CARDANO. RUMORED TO BE MORE THAN ONE PERSON,
    //             AF REFUSES TO COMMENT ON THIS.
    //           </Typography>
    //         </AccordionDetails>
    //       </Accordion>
    //     </div>
    //   </div>
    // );
  }

export default Team;
