import React from 'react';
import mmkLogo from '../../logo.svg';
import './footer.css';

const Footer = () => (
  <div className="mmk__footer section__padding">
    <div className="mmk__footer-heading">
      <h1 className="magic__font"> THE JOURNEY BEGINS WITH MAGIC</h1>
    </div>

    <div className="mmk__footer-links">
      <div className="mmk__footer-links_logo">
        <img src={mmkLogo} alt="mmk_logo" />
        <p>MINI MAGICS KINGDOM, <br /> ALL RIGHTS RESERVED</p>
      </div>
      <div className="mmk__footer-links_div">
        <h4>COMPANY</h4>
        <p><a href="https://mini-magics-kingdom.gitbook.io/untitled/terms-and-conditions/personal-use">TERMS & CONDITIONS</a></p>
      </div>
      <div className="mmk__footer-links_div">
        <h4>GET IN TOUCH</h4>
        <p><a href="https://discord.gg/PU7mksfxfE">DISCORD</a></p>
        <p><a href="https://twitter.com/MiniMagicsNFT">TWITTER</a></p>
      </div>
    </div>
    <div className="mmk__footer-copyright">
      <p>@2022 MMK. ALL RIGHTS RESERVED.</p>
    </div>
  </div>
);

export default Footer;
