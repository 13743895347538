import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="mmk__cta">
    <div className="mmk__cta-content">
      <p>01/12/2023 WAVE 2</p>
      <h3>MINT YOUR MAGIC TODAY TO JOIN THE KINGDOM</h3>
    </div>
    <div className="mmk__cta-btn"> 
       <button type="button">SOLD OUT</button>
    </div>
  </div>
);

export default CTA;
