/* eslint-disable */
import React, { useState } from "react";
import Images from "../../assets/testimages.js";
import "./inventory.css";

function Inventory() {
  const [selectedImg, setSelectedImg] = useState(Images[0]);

  return (
    <div className="Inventory">
      <div className="container">
        <div className="container__selected">
          <img src={selectedImg} alt="Selected" className="selected" />
        </div>
        <div className="imgContainer">
          {Images.map((img, index) => (
            <img
              style={{ border: selectedImg === img ? "4px solid purple" : "" }}
              key={index}
              src={img}
              alt="magic"
              onClick={() => setSelectedImg(img)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Inventory; 