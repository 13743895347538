import React from 'react';

import { Footer, Roadmap, Team, Whatmmk, Header } from './containers';
import { CTA, Brand, Navbar } from './components';

import './App.css';

const App = () => (
  <div className="App">
    <div>
      <Navbar />
      <Header />
    </div>
    <Brand />
    <Whatmmk />
    <Team />
    <Roadmap />
    <CTA />
    {/* <Inventory />
    <Mintpage /> */}
    <Footer />
  </div>
);

export default App;
